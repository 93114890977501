export const regExFromString = (value) => {
  return value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const colorLog = (
  message,
  color = "white",
  background = "black",
  size = "2em"
) => {
  console.log(
    `%c${message}`,
    `background:${background}; color:${color}; font-size:${size}; `
  );
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};

export const generateRndmString = ({characters=32, prefix=""}={}) => {
  let rndm = prefix;
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < characters; i++){
    rndm += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return rndm
};

export const formatSearchDateTime = (dateObj, timeObj) => {
  const yy = dateObj.getFullYear().toString()
  const mm = ("0"+(dateObj.getMonth() + 1)).toString().slice(-2)
  const dd = ("0"+dateObj.getDate()).toString().slice(-2)
  const h = ("0"+timeObj.getHours()).toString().slice(-2)
  const m = ("0"+timeObj.getMinutes()).toString().slice(-2)
  return `${yy}-${mm}-${dd} ${h}:${m}:00`
};

export const formatCallDuration = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const minString = (minutes < 10 ? "0" : "") + minutes;
  const seconds = (totalSeconds % 60);
  const secString = (seconds < 10 ? "0" : "") + seconds;
  return (minString+":"+secString);
};

export const firstUppercase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const checkIfNauta = (email) => {
  return email.indexOf("@nauta.co.cu") > -1 || email.indexOf("@nauta.com.cu") > -1
}

export const getCookie = name => {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export const removeAccents = str => {
  return typeof str == "string"? str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""):""
} 

export const getMobileOperatingSystem = ()=>{
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

export const unicodeUnEscape = (string)=> {
  return string.replace(/%u([\dA-Z]{4})|%([\dA-Z]{2})/g, function(_, m1, m2) {
    return String.fromCharCode(parseInt("0x" + (m1 || m2)));
  })
}

export const stripPrefix = (prefix, number)=> {
  if(prefix && number.startsWith(prefix)){
    return number.substring(prefix.length)
  }else{
    return number
  }
}

export const formatCosmeticPhoneNumber = (prefix, number)=> {
  if(prefix && number.startsWith(prefix)){
    return `+${prefix} ${number.substring(prefix.length)}`
  }else{
    return `+${number}`
  }
}

export const replaceWPContentSlugLocaleCurrency = (content, locale, currency) => {
  return content ? content.replace(/SLUG_LANGUAGES\//g, String(locale + "-" + String(currency).toUpperCase()) === process.env.GATSBY_DEFAULT_LANGUAGE ? "" : String(locale + "-" + String(currency).toUpperCase() + "/")) : null
}

export const getPhoneLength = (phone)=>{
  const phoneLength = phone?.tel?.length || 0
  const dialCodeLength = phone?.country?.dialCode?.length || 0
  const result = phoneLength - dialCodeLength
  return result<0?0:result
}