// TRACKING EVENTS
export const checkoutTracking = (orderId, data, firebase = null) => {
  let conversionData = {    //'send_to': 'AW-409257642/pupwCNbQjIoCEKqNk8MB',
    'transaction_id': orderId,
    "affiliation": "Cuballama store",
    'value': data.amount,
    'currency': data.offers[0].currency,//FALTA
    "tax": 0,
    "shipping": 0,
    "items": data.offers.map((offer, index)=>{
      return {
        "id": offer.offerId,
        "name": `${offer.type} ${offer.finalPrice} - ${offer.offerId}`,//FALTA //FALTA 
        "list_name": "",
        "brand": "Cuballama",
        "category": offer.type,
        "variant": offer.relatedOffers?offer.relatedOffers.map((relatedOffer)=>{
          return relatedOffer.offerId
        }).toString():null,
        "list_position": index,
        "quantity": 1,
        "price": offer.finalPrice
      }
    })
  }
  //Analytics
  typeof window !== "undefined" && typeof window.gtag === "function" && window.gtag('event', 'purchase', conversionData)
  conversionData.send_to = 'AW-409257642/pupwCNbQjIoCEKqNk8MB'
  //Ads
  typeof window !== "undefined" && typeof window.gtag === "function" && window.gtag('event', 'purchase', conversionData)
  
  for(let c=0; c< data.offers.length; c++){
    try{
      var sum = 0;
      if(data.offers[c].relatedOffers && data.offers[c].relatedOffers.length > 0){
        for (var i = 0; i < data.offers[c].relatedOffers.length; i++) {
          sum += data.offers[c].relatedOffers[i].finalPrice
        }
      }
      typeof window !== "undefined" && typeof window.gtag === "function" && window.gtag('event', 'conversion', {
        'send_to': getAdsToken(data.offers[c].type),
        'value': data.offers[c].finalPrice+sum,
        'currency': data.offers[c].currency,
        'transaction_id': orderId
      });
      //firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, listObjects);
    }catch(e){
      console.log(e)
    }
  }
  //Firebase
  /*const listObjects = {
    transaction_id: orderId,
    affiliation: 'www.cuballama.com',
    currency: data.offers[0].currency,
    value: data.amount, // Total Revenue
    items: data.offers.map((offer)=>{
      return  {
        item_id: offer.offerId,
        item_name: `${offer.type} ${offer.finalPrice} - ${offer.offerId}`,
        item_category: offer.type,
        price: offer.finalPrice
      }
    })
  }
  firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, listObjects);*/
  
  //Segment
  typeof window !== "undefined" && window.analytics && window.analytics.track('Order Completed', {
    currency: data.offers[0].currency,
    orderId: orderId,
    //paymentMethodType: "TU_BOLSILLO",
    products: data.offers.map((offer)=>{
      return  {
        id: offer.offerId,
        currency: offer.currency,
        category: offer.type,
        quantity:1,
        price: offer.finalPrice
      }
    }),
    //threeDS:"",
    total:data.amount
  })
  //Adjust
  typeof window !== "undefined" && window.Adjust && window.Adjust.trackEvent({
    eventToken: adjustTokens["ORDER_COMPLETED"],
    revenue: data.amount,
    currency: data.offers[0].currency
  })
}
//GTAG
export const gtagRegisterEvent = () => {
  try{
    typeof window !== "undefined" && typeof window.gtag === "function" && window.gtag('event', 'conversion', {'send_to': 'AW-409257642/5fdBCP_H8YkCEKqNk8MB'});
    //firebase.analytics().logEvent(firebase.analytics.EventName.SIGN_UP);
  }catch(e){
    console.log(e)
  }
};
//SEGMENT && ADJUST
export const trackingEvent = ({eventString, data = null, sendToSegment = true, sendToAdjust = true}) =>{
  const segment = typeof window !== "undefined" &&  window.analytics?true:false
  const adjust = typeof window !== "undefined" &&  window.Adjust?true:false
  try{
    if (segment && sendToSegment){
      switch(eventString){
        case "PRODUCT_ADDED":
          window.analytics.track('Product Added', {
            cart_id: data.cart_id,
            category: data.product.type,
            currency: data.product.offer.currency,
            name: data.product.offer.title,
            price: data.product.offer.finalPrice,
            product_id: data.product.offer.id,
            quantity: 1
          })
        break;
        case "PRODUCT_REMOVED":
          window.analytics.track('Product Removed', data)
        break;
        case "TRACK_PAGE":
          window.analytics.page()
        break;
        case "IDENTITY":
          window.analytics.identify(data.accountId, {
            //anonymousId: data.uuid,
            //firstName:"",
            //phoneNumber:"",
            userId: data.accountId
          });
        break;
        case "SIGNED_UP":
          window.analytics.track("Signed Up", data)
        break;
        case "SIGNED_IN":
          window.analytics.track("Signed In", data)
        break;
        case "TOPUP_LOADING":
          window.analytics.track("Recarga Celular Landing", {
            country: data.offerOperator,
            //prefix: data.prefix,
            phone: data.data.phone,
            operator: data.data.operator,
            fullOffer: data.fullOffer
          })
        break;
        case "NAUTA_LANDING":
          window.analytics.track("Recarga Nauta Landing", {
            email: data.data.email,
            fullOffer: data.fullOffer
          }) 
        break;
        case "INIT_CHECKOUT":
          window.analytics.track("Checkout Started",data)
        break;
        case "PAYMENT_DECLINED":
          window.analytics.track("Payment Declined",data)
        break;
        default:
          //console.log(no aplica)
      }
    }
    if(adjust && sendToAdjust && adjustTokens[eventString]){
      window.Adjust.trackEvent({
        eventToken: adjustTokens[eventString],
      })
    }
  }catch(e){
    console.log(e)
  }  
}
//Adjust tokens
const adjustTokens = {
  "PRODUCT_ADDED": "xjs3oe",
  //"PRODUCT_REMOVED": "erv4e9",
  //"TRACK_PAGE": "q78ybi", //Navigation Change
  //"IDENTITY": "",
  "SIGNED_UP": "xhkcsl",
  "SIGNED_IN": "wp2a5y",
  //"TOPUP_LOADING": "",
  //"NAUTA_LANDING": "",
  //"INIT_CHECKOUT": "rlmiag",
  //"CHECKOUT": "o29ry7",
  "PAYMENT_DECLINED": "llcr4a",
  //NO usadas
  //"HOME": "r8rs2l",//Pantalla
  //"LOGIN":"26tg3d",//Pantalla
  //"NOTIFICATIONS_ON":"jxbd89",
  "ORDER_COMPLETED":"8pcpjv"
}
//PRIVATE FUNCTIONS           
function getAdsToken(type){
  switch(type){
    case "TOPUP":
      return 'AW-409257642/NKvhCPPB8YkCEKqNk8MB'
    case "TOPUP_NAUTA":
      return 'AW-409257642/V9kOCJvgy4kCEKqNk8MB'
    case "REMESA":
      return 'AW-409257642/FcatCM7-9IkCEKqNk8MB'
    default: //case "SALDO" o "BALANCE
      return 'AW-409257642/lRy7CIHby4kCEKqNk8MB'
  }
}