import React, { useEffect, useState, useContext } from "react"
//Context
import GlobalContext from "@context/global/GlobalContext";
import { Script } from "gatsby"

export default function CollectionScripts() {
  const { permissionGranted } = useContext(GlobalContext)
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [loadGranted, setLoadGranted] = useState(false);
  
  const onScriptsStatusChange = ()=>{
    //Sólo cuando se resuleva si hay permiso o no  
    if(permissionGranted !== null){
      //Sólo se ejecuta una vez
      if(!alreadyLoaded){
        setAlreadyLoaded(true)
        setLoadGranted(permissionGranted)
        //Script de gtag con variable de permiso
        if(typeof window !== undefined && typeof window.gtag === "function"){
          window.gtag("consent", 'update', {'analytics_storage': permissionGranted ? 'granted' : 'denied'});
        }
      }
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onScriptsStatusChange, [permissionGranted]);

  return(
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_FIREBASE_ANALYTICS_ID}`}
        onLoad={()=>{
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          if(typeof window.gtag === "function"){
            window.gtag('js', new Date()); 
            window.gtag('config', process.env.GATSBY_FIREBASE_ANALYTICS_ID, {page_path: window.location.pathname});
            window.gtag('config', process.env.GATSBY_GOOGLE_TAG_TRACKING_ID, {page_path: window.location.pathname});  
          }
        }}
      />
      {loadGranted?
        <>
          <Script id="adjust-cdn"
            dangerouslySetInnerHTML={{
            __html:`
              !function(t,a,e,r,s,l,d,n,o){t.Adjust=t.Adjust||{},t.Adjust_q=t.Adjust_q||[];for(var c=0;c<l.length;c++)d(t.Adjust,t.Adjust_q,l[c]);n=a.createElement("script"),o=a.getElementsByTagName("script")[0],n.async=!0,n.src="https://cdn.adjust.com/adjust-latest.min.js",n.onload=function(){for(var a=0;a<t.Adjust_q.length;a++)t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust,t.Adjust_q[a][1]);t.Adjust_q=[]},o.parentNode.insertBefore(n,o)}(window,document,0,0,0,["initSdk","trackEvent","addGlobalCallbackParameters","addGlobalPartnerParameters","removeGlobalCallbackParameter","removeGlobalPartnerParameter","clearGlobalCallbackParameters","clearGlobalPartnerParameters","switchToOfflineMode","switchBackToOnlineMode","stop","restart","gdprForgetMe","disableThirdPartySharing"],function(t,a,e){t[e]=function(){a.push([e,arguments])}});  
              Adjust.initSdk({
                appToken:'${process.env.GATSBY_ADJUST_TOKEN}',
                environment:'${process.env.GATSBY_ADJUST_ENVIRONMENT}',
                logLevel:'${process.env.GATSBY_ADJUST_ENVIRONMENT === 'sandbox'?'verbose':'error'}'
              }); 
              `
            }}
          />
          <Script id="segment-cdn"
            dangerouslySetInnerHTML={{
            __html:`
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_KEY}";analytics.SNIPPET_VERSION="4.15.2";
              analytics.load('${process.env.GATSBY_SEGMENT_KEY}');
              analytics.page();
              }}();
            `
            }}
          />
        </>
      :null}
    </>
  )
}