import {URL_LOGOUT} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import {removeFromStorage} from '@utils/storage'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

const Logout = async (axiosInstance) => {
  const apiURL = `${URL_LOGOUT}`
  const response = await processCall({axiosInstance: axiosInstance, method: "POST", url: apiURL, useToken: "TOKEN_YES", callData:{body:null, bodyEncode: "JSON"}})
  
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  //if(evaluation.status === "ok"){
    //Si hay error también se fuerza el logout
    removeFromStorage("token")
    removeFromStorage("stamp")
    removeFromStorage("agent")
  //}
  return evaluation
}

export default Logout