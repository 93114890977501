import {URL_GET_CREDIT} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse, baseCurrency) => {
  const creditString = (baseCurrency === "USD")?"CALL_CREDIT":`${baseCurrency}_CREDIT`;
  const callCredit = apiResponse.data.data.data.elements.find(function(element) {
    return element.creditType === creditString ? true : false;
  });
  return {amount: callCredit?.amount || 0}
}

const getCredit = async ({currency, axiosInstance}) => {
  
  const apiURL = `${URL_GET_CREDIT}`
  //Se procesa la petición
  const response = await processCall({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  if(evaluation.status === "ok"){
    evaluation.formattedData = formatApiResponse(response, currency)
  }
  return evaluation
}
export default getCredit