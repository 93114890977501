import { useState, useContext} from 'react'
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from '@customHooks/common/useCancellablePromise'
import useModal from '@customHooks/common/modal/useModal'
import useLocalizedRoutes from '@customHooks/common/useLocalizedRoutes'; //useIntl,
//Getters
import getUserInfoService from '@services/common/account/getUserInfo'
import getAccountInfoService from '@services/common/account/getAccountInfo'
import getCartContentService from '@services/cll2/cart/getCartContent'
import getCreditService from '@services/common/account/getCredit'
import getAvailableServices from '@services/common/account/getAvailableServices'
import getCashTicketOptionsService from '@services/common/paymentMethods/getCashTicketOptions'
//UTILS
import {trackingEvent} from "@utils/tracking";
import * as Sentry from "@sentry/browser";

export function useAllInfo() {
  const { userInfo,
    setUserInfo,
    setCurrency,
    accountInfo,
    setAccountInfo,
    credit,
    setCredit,
    cartCount,
    setCartCount,
    cart,
    setCart,
    hasData,
    setHasData,
    axiosInstance,
    setAvailableServices,
    setCashTicketOptions
  } = useContext(GlobalContext)
  const {navigate} = useLocalizedRoutes()
  const { cancellablePromise } = useCancellablePromise()
  const [loading, setLoading] = useState(false)
  const { handleModal } = useModal()

  const getAllInfo = (callback = null)=>{
    if(!loading){
      setLoading(true)
      const promise1 = cancellablePromise(getUserInfoService(axiosInstance))
      const promise2 = cancellablePromise(getAccountInfoService(axiosInstance))
      const promise3 = cancellablePromise(getCartContentService(axiosInstance))
      const promise4 = cancellablePromise(getAvailableServices(axiosInstance))
      
      //Primera ronda de datos
      Promise.all([promise1, promise2, promise3, promise4])
      .then(responses => {
        let data = {}
        responses.forEach((response, index) => {
          if(!response.canceled){        
            if(response.status === "ok"){
              switch(index){
                case 0:
                  setUserInfo(response.formattedData)
                  setCurrency(response.formattedData.currency)
                  data.accountId = response.formattedData.accountId
                  data.currency = response.formattedData.currency
                  data.uuid = response.formattedData.uuid
                  data.country = response.formattedData.country
                break;
                case 1:
                  setAccountInfo(response.formattedData)
                  setHasData(true)
                  data.firstName = response.formattedData.firstName
                  data.phoneNumber = response.formattedData.phoneNumber
                break;
                case 2:
                  setCartCount(response.formattedData.offers.length)
                  setCart(response.formattedData)
                break;
                default: //case 3
                  setAvailableServices(response.formattedData)
              }
            }
          }
        })
        return data
      })
      .then(data => {
        const promise5 = cancellablePromise(getCreditService({currency: data.currency, axiosInstance}))
        const promise6 = cancellablePromise(getCashTicketOptionsService({country: data.country, axiosInstance}))
        //Segunda ronda a partir de datos obtenidos
        Promise.all([promise5, promise6])
        .then(responses => {
          responses.forEach((response, index) => {
            if(!response.canceled){        
              if(response.status === "ok"){
                switch(index){
                  case 0: //Crédito actualizado
                    setCredit(response.formattedData.amount)
                  break;
                  default: //Opciones de pago con cash
                    setCashTicketOptions(response.formattedData)
                }
              }
            }
          })
        })
        .then(() => {
          //tercer paso, callback para indicar el final del proceso
          Sentry.setUser({
            id: data?.accountId?? "N/A",
            username: data?.firstName?? "N/A",
          })
          trackingEvent({eventString: "IDENTITY", data})  
          setLoading(false)
          if(typeof callback === "function"){
            callback(data.currency) //userInfoCallback en useAccessValidation
          }
        })
      })
    }
  }

  const getUserInfo = (callback = null)=>{
    if(!loading){
      setLoading(true)
      cancellablePromise(getUserInfoService(axiosInstance))
      .then(response => {
        if(!response.canceled){
          if(response.status === "ok"){
            setUserInfo(response.formattedData)
            setCurrency(response.formattedData.currency)
            if(typeof callback === "function"){
              callback({status:"ok", currency:response.formattedData})
            }
          }else{
            //Mostrar alerta
            if(response.useAlert) handleModal(response.alertData)
            //Hacer redirect
            if(response.useRedirect) navigate(response.route || "HOME")
            //Mostrar error en form
            //if(response.formId) setFormResponse({formId: response.formId, multiText: response.multiText, message: response.message })
          }
        }
      }).catch(error=>{
        console.log(error)
      })
    }
  }

  const getAccountInfo = (callback = null)=>{
    if(!loading){
      setLoading(true)
      cancellablePromise(getAccountInfoService(axiosInstance))
      .then(response => {
        if(!response.canceled){
          if(response.status === "ok"){
            setAccountInfo(response.formattedData)
            setHasData(true)
            if(typeof callback === "function"){
              callback({status:"ok", data:response.formattedData})
            }
          }else{
            //Mostrar alerta
            if(response.useAlert) handleModal(response.alertData)
            //Hacer redirect
            if(response.useRedirect) navigate(response.route || "HOME")
            //Mostrar error en form
            //if(response.formId) setFormResponse({formId: response.formId, multiText: response.multiText, message: response.message })
          }
        }
      }).catch(error=>{
        console.log(error)
      })
    }
  }

  //EL balance y su valor en el servicio depende del currency / idioma
  const updateUserCredit = ({currency=null, callback=null}={})=>{
    cancellablePromise(getCreditService({currency: currency || userInfo?.currency, axiosInstance}))
    .then(response => {
      if(!response.canceled){
        if(response.status === "ok"){
          setCredit(response.formattedData.amount)
          if(typeof callback === "function"){
            callback({status:"ok", currency:currency, credit: response.formattedData.amount})
          }
        }
      }
    }).catch(error=>{
      console.log(error)
    })
  }
  return {loading, userInfo, setUserInfo, accountInfo, setAccountInfo, cartCount, cart, credit, hasData, updateUserCredit, getUserInfo, getAccountInfo, getAllInfo}
}