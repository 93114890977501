import {URL_GET_USER} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

//Formateo específico de datos de cada servicio
const formatApiResponse = apiResponse => {
  const root =  apiResponse.data.data
  const tempOptions = []
  /*if(root.sendPromotions !== null && root.sendPromotions !== undefined){
    tempOptions.push({title:"sendPromotions", returnTitle: "subscribePromotion", state: root.sendPromotions})
  }*/
  if(root.sendPromotionsSMS !== null && root.sendPromotionsSMS !== undefined){
      tempOptions.push({title:"sendPromotionsSMS", returnTitle: "subscribePromotion", state: root.sendPromotionsSMS})
  }
  if(root.sendPromotionsEmail !== null && root.sendPromotionsEmail !== undefined){
      tempOptions.push({title:"sendPromotionsEmail", returnTitle: "subscribeEmail", state: root.sendPromotionsEmail})
  }
  if(root.sendPromotionsWhatsapp !== null && root.sendPromotionsWhatsapp !== undefined){
      tempOptions.push({title:"sendPromotionsWhatsapp", returnTitle: "subscribeWhatsapp", state: root.sendPromotionsWhatsapp})
  }
  const data = {
    accountId: root.accountId,
    country: root.country,
    currency: root.currency,
    tuBolsilloServices: root.tuBolsilloServices,
    uuid: root.uuid,
    notificationsConfig: tempOptions
  }
  return data
}

const getUserInfo = async (axiosInstance) => {
  const apiURL = `${URL_GET_USER}`
  //Se procesa la petición
  const response = await processCall({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  if(evaluation.status === "ok"){
    evaluation.formattedData = formatApiResponse(response.data)
  }
  return evaluation
}
export default getUserInfo