import {URL_GET_ALL_CART_ITEMS} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

const formatApiResponse = apiResponse => {
  const data = {
    id: apiResponse.data.data.cartId,
    total: apiResponse.data.data.totalPrice,
    offers: apiResponse.data.data.offers
  }
  return data
}

const getCartContent = async (axiosInstance) => {
  const apiURL = `${URL_GET_ALL_CART_ITEMS}`
  const response = await processCall({axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES", callData:{body:null, bodyEncode: "JSON"}})
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  if(evaluation.status === "ok"){
    evaluation.formattedData = formatApiResponse(response.data)
  }
  return evaluation
}
export default getCartContent