import React from "react";
import {GlobalProvider} from "@context/global/GlobalContext";
import {ModalProvider} from "@context/modal/ModalContext";
import LoadingPage from "@advanced/cll2/layout/loadingPage";
import ErrorFallback from "@advanced/cll2/errorHandling/errorFallback"
import CollectionScripts from "@advanced/cll2/layout/collectionScripts";
//HOOK
import {ErrorBoundary} from 'react-error-boundary'
//import {trackingEvent} from "@utils/tracking";
//import "firebase/analytics"

import { checkStorage, getFromStorage, addToStorage } from '@utils/storage'
import { generateRndmString } from "@utils/helper"
//import "firebase/analytics"
import '@styles/global.css'
import '@styles/material.css' //react-phone-input-2

// SEGMENT
/*export const onRouteUpdate = () => {
  trackingEvent({eventString: "TRACK_PAGE"})       
};*/
//

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalProvider>
      <ModalProvider>
        {element}
      </ModalProvider>
    </GlobalProvider>
  );
};

export const wrapPageElement = ({ element }) => {
  return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CollectionScripts/>
         <LoadingPage element = {element}/>
      </ErrorBoundary>
  );
};

//REDIRECT DeepLinks
/*export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('searchData', window.location.search?window.location.search:null);
  };
}*/

export const onInitialClientRender = () => {
  if(checkStorage()){
    const loadScript = ({src=null, callback = null}={})=>{
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.type = "application/javascript";
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    const deviceId = getFromStorage("deviceId")
    if(!deviceId){
      const initFingerprintJS = ()=>{
        window.FingerprintJS.load({token: 'wkoC8U5xXuRxMezDGztI', endpoint: 'https://fp.cuballama.com'})
        .then(fp => fp.get())
        .then(result => {
          const visitorId = (result && result.visitorId)?result.visitorId:generateRndmString(32,"F_")
          window.visitorId = visitorId
          addToStorage("deviceId",visitorId)
        })
        .catch(error => {
          console.log(error)
          const visitorId = generateRndmString(32,"F_")
          window.visitorId = visitorId
          addToStorage("deviceId",visitorId)
        })
      }
      loadScript({
        src:'https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs-pro@3/dist/fp.min.js',
        callback:initFingerprintJS
      })
    }else{
      window.visitorId = deviceId
    }
    //loadScript({
      //src:'/scripts/segment.js'
    //})
  }
}

/*export const onRouteUpdate = ({ location }) => {
  console.log("Cambio de página---------------")
  //console.log(location)
  
}*/