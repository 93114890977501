import React from "react"
//Components
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
//SVG
import cbllLogoColor from '/static/images/svg/cbll-logotipo-color.svg';

const PageLoader = () => {
    return <Box sx={{ display: 'flex', height:"100%", alignItems:"center", justifyContent:"center"}}>
        <Stack alignItems="center" spacing={3} sx={{width:"100%", p:3}}>
        <Box component="img" src={cbllLogoColor} alt="Logo Cuballama" sx={{width:"100%", maxWidth:"220px"}}/>
        <CircularProgress color="primary"/>
        </Stack>
    </Box>
}
export default PageLoader