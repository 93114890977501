import {URL_AVAILABLE_SERVICES} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

const formatApiResponse = apiResponse => {
  return apiResponse.data.data.elements
}

const getAvailableServices = async (axiosInstance) => {
  const apiURL = `${URL_AVAILABLE_SERVICES}`
  //Se procesa la petición
  const response = await processCall({axiosInstance: axiosInstance, method: "GET", url: apiURL, useToken: "TOKEN_YES"})
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  if(evaluation.status === "ok"){
    evaluation.formattedData = formatApiResponse(response.data)
  }
  return evaluation
}
export default getAvailableServices
