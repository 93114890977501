//BASE
export const CLIENT_ID = "cll2_web";
export const URL_BASE = process.env.GATSBY_API_BASE;
export const URL_TRAVELS_BASE = process.env.GATSBY_VIAJES_API_BASE;
export const AUTH_PASS = process.env.GATSBY_AUTH_PASS;
export const AUTH_PASSB = process.env.GATSBY_AUTH_PASSB;
//Token
export const URL_REFRESH_TOKEN = `uaa/oauth/token`;
//Verifications
export const URL_2FA_VERIFICATION = "uaa/device/verify";
export const URL_EMAIL_VERIFICATION = "uaa/email/change/verify";
//Login
export const URL_LOGOUT = "uaa/secured/logout";
export const URL_LOGIN_STEP1 = `uaa/login/passwordless`;
export const URL_LOGIN_STEP2 = `uaa/oauth/token`;
export const URL_LOGIN_STEP3 = `uaa/secured/data`;
export const URL_LOGIN_STEP4 = `uaa/secured/data/email`;
export const URL_PASSWORD_STEP1 = `uaa/recoverpw/init`;
export const URL_PASSWORD_STEP2 = `uaa/recoverpw/verify`;
//Account info
export const URL_GET_USER = "users/secured/data";
export const URL_GET_USER_ACCOUNT = "uaa/secured/data";
export const URL_GET_CREDIT = "orders/secured/usercredit";
//Account update
export const URL_USER_UPDATE_NAME = `uaa/secured/name/update`;
export const URL_USER_UPDATE_EMAIL = `uaa/secured/data/email`;
export const URL_USER_UPDATE_PASS = `uaa/secured/data/password`;
export const URL_USER_UPDATE_PHONE = `uaa/secured/phone/update`;
export const URL_USER_UPDATE_PHONE_VERIFY = `uaa/secured/phone/verify`;
//Account settings
export const URL_GET_CURRENCIES = "users/currency/get";
export const URL_CHANGE_CURRENCY = "users/secured/currency/add?currency=";
export const URL_GET_RATE = "users/secured/rates/get?";
export const URL_UPDATE_BALANCE = "users/secured/credit/transfer";
export const URL_NOTIFICATIONS_CONFIG = "users/secured/subscribePromotion";
//Contacts
export const URL_GET_CONTACTS = "users/secured/contacts/";
export const URL_ADD_CONTACT = "users/secured/contacts/add";
export const URL_DELETE_CONTACT = "users/secured/contacts/delete";
//Call Rates
export const URL_SERVICE_RATES = "users/call/rates"; //users/secured/call/rateByMinute
//Contact Promoter 
export const URL_FIND_ADVISOR = "https://n8n.prod.cubatelefono.com/webhook/get-promotors";
//Become Promoter 
export const URL_BECOME_ADVISOR = "https://n8n.prod.cubatelefono.com/webhook/nuevo-promotor";
//Cart
export const URL_ADD_TO_CART = "cart/secured/CUBALLAMA/add";
export const URL_COUNT_CART_ITEMS = "cart/secured/CUBALLAMA/count";
export const URL_REMOVE_FROM_CART = "cart/secured/CUBALLAMA/remove/";
export const URL_GET_ALL_CART_ITEMS = "cart/secured/CUBALLAMA/data";
//Catalog Services
export const URL_AVAILABLE_SERVICES = "catalog/secured/productTypes";
//Referrals
export const URL_CREATE_REFERRAL_CODE = "catalog/secured/referral/create-code";
// Operators
export const URL_COUNTRIES_ALL = "catalog/secured/topup/countries?size=100";
export const URL_OPERATOR_ALL = "catalog/secured/operator";
export const URL_OPERATOR_BY_NAME = "catalog/secured/operator/name/";
export const URL_OPERATORS_BY_COUNTRY = "catalog/operator/country/";
// Offers
export const URL_OFFERS_BASE = "catalog/offers/cll/";
export const URL_OFFER_BY_ID = "catalog/offer?id=";
export const URL_HISTORY = "reports/secured/history";//
export const URL_HISTORY_VISIBLE = "reports/secured/visible";
export const URL_HISTORY_TOPUP_MODIFY = "orders/secured/fo/topup/update";
// Remittances
export const URL_CASHOUT_INFO = "catalog/secured/cashout/cashoutinfo";
export const URL_CASHOUT_CURRENCIES = "catalog/secured/cashout/currencies";
export const URL_VALIDATE_CASHOUT = "catalog/secured/cashout/cashoutvalidator?";//
// Remittances cards
export const URL_ADD_REMITTANCE_CARD = "customer/secured/prepaidcard/add";
export const URL_DELETE_REMITTANCE_CARD = "customer/secured/prepaidcard/deletebyid?id=";
export const URL_GET_RELATED_CARDS = "customer/secured/prepaidcard/all"
export const URL_GET_CARD_BY_PHONE = "customer/secured/prepaidcard/byaccountandphone?phoneNumber="
export const URL_GET_CARD_BY_CARDID = "customer/secured/prepaidcard/bycardid?cardId="
export const URL_BUY_CARD_AVAILABILITY = "orders/secured/prepaid-card/can-be-assigned-card?contactNumber=";
// Bank Remittances
export const URL_BANK_REMITTANCE_RECIPIENT = "orders/secured/remesa/tobankaccount/recipient"
export const URL_BANK_REMITTANCE_GET_SENDER = "users/secured/user/address"
export const URL_BANK_REMITTANCE_SENDER = "orders/secured/remesa/tobankaccount/sender"
// Address
export const URL_ADDRESS_BY_USER_NUMBER = "users/secured/contacts/address/id?mobile=";
export const URL_ADD_ADDRESS = "users/secured/contacts/address/add";
//Balance request
export const URL_REQUEST_BALANCE = "reports/secured/checkout/request/credit"
export const URL_BALANCE_REQUEST = "reports/secured/credit/request"
export const URL_PAY_REQUESTS = "reports/secured/checkout/request"
export const URL_NON_SECURE_PAY_REQUESTS = "reports/checkout/request"
export const URL_CANCEL_PAY_REQUESTS = "reports/secured/checkout/request/cancel/"
export const URL_EXTERNAL_CHECKOUT   = "orders/third-party/checkout"
// Gift Cards
export const URL_GET_GIFT_CARDS = "users/secured/user/giftcards";
export const URL_GET_IMAGES = "users/secured/product/image-url?product=CREDIT_TO_USER&size=100&page=0"
export const URL_EXCHANGE_GIFT_CARD = "users/secured/user/claimgiftcard?giftcardId=";
export const URL_EXCHANGE_GIFT_CARD_COUPON = "users/secured/balance/gift/coupon/";
//Checkout and payment
export const URL_GET_BRAINTREE_TOKEN = "customer/secured/braintree/token";
export const URL_GET_PAYMENTMETHOD_NONCE = "customer/secured/nonce?paymentMethodId=";
export const URL_GET_BEFORE_PAYING = "customer/secured/before-to-pay";
//export const URL_PAYMENT_METHODS = "customer/secured/paymentmethod/all";
export const URL_PAYMENT_METHODS = "customer/secured/paymentmethodandusercredit/all";
export const URL_CASH_TICKET_OPTIONS = "customer/secured/dlocal/paymentmethods/";
export const URL_ADD_CASH = "customer/secured/identitydocument/add";

export const URL_ADD_PAYPAL_CREDIT_CARD = "customer/secured/creditcard/add";
export const URL_ADD_CREDIT_CARD = "customer/secured/card/v2/add";
export const URL_CHECK_REQUIRE_CREDIT_CARD_ZIP = "customer/secured/countries/requirepc";
export const URL_UPDATE_CREDIT_CARD_ZIP = "customer/secured/bt/paymentmethod/update";
export const URL_VERIFY_CREDIT_CARD = "customer/secured/validate/creditcard";
export const URL_DELETE_PAYMENT_METHOD = "customer/secured/paymentmethod/delete";
export const URL_GET_CHECKOUT_TOTAL = "catalog/secured/checkout/total";
//export const URL_CHECKOUT_PAYPAL = "orders/secured/paypal/sdk/checkout";
export const URL_CHECKOUT = "orders/secured/paymentmethod/checkout";
export const URL_CYBERSOURCE_SETUP = "customer/secured/cybersource/setupPayerAuth";
export const URL_CYBERSOURCE_CHECK_ENROLLMENT = "customer/secured/cybersource/checkEnrollment";
export const URL_CYBERSOURCE_RETURN_URL = "customer/3ds/endStep";

//THIRD PARTIES
export const URL_PROIP = `https://pro.ip-api.com/json/?key=kYgryVqIIc1JvvW`;
export const URL_BAMBORA_NONCE = "https://api.na.bambora.com/scripts/tokenization/tokens";
export const URL_PAYPAL_OBJECTS = "https://www.paypalobjects.com/api/checkout.js";
export const URL_GOOGLE_PAY = "https://pay.google.com/gp/p/js/pay.js";
export const URL_BRAINTREE_CLIENT = "https://js.braintreegateway.com/web/3.76.2/js/client.min.js";
export const URL_BRAINTREE_CHECKOUT = "https://js.braintreegateway.com/web/3.76.2/js/paypal-checkout.min.js";
export const URL_BRAINTREE_SECURE = "https://js.braintreegateway.com/web/3.76.2/js/three-d-secure.min.js";
export const URL_BRAINTREE_APPLE = "https://js.braintreegateway.com/web/3.76.2/js/apple-pay.min.js";
export const URL_BRAINTREE_GPAY = "https://js.braintreegateway.com/web/3.76.2/js/google-payment.min.js";
export const URL_UNSUBSCRIBE = "https://qzmf05g2mc.execute-api.us-east-1.amazonaws.com/Prod/cuballama/unsubscribe/";

//VIAJES PAGOS
export const URL_TRAVELS_PAYMENT = `booking/async-check-payment`;
export const URL_TRAVELS_EXTERNAL_CHECKOUT = "booking/do-payment";

//VIAJES AUTOS
export const URL_CAR_PLACES = `travels/rentcarplaces?term=`;
export const URL_CAR_CATEGORIES = `travels/car/caterogies`;
export const URL_CAR_TRANSMISSIONS = `travels/car/tranmissions`;
export const URL_CAR_TERMS = `travels/car/terms?termsId=14&lang=es`;
export const URL_CAR_SEARCH = `travels/search-cars`;
export const URL_CAR_CHECK_FARES = `travels/secured/check-car-fares`;
export const URL_CAR_BOOKING = `travels/secured/car-booking`;

//PROMOS
//export const URL_VISA_MEXICO = `https://n8n.prod.cubatelefono.com/webhook/formulario-visa`;
export const URL_VISA_MEXICO = `https://webhook.site/cll-webhook`;
