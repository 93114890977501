import React, {useEffect, useState, Suspense} from "react"
//components
import PageLoader from "@basic/common/loading/pageLoader"
import {getFromStorage, getRefreshToken, addToStorage, removeFromStorage} from '@utils/storage'
import { AUTH_PASS, URL_REFRESH_TOKEN, CLIENT_ID } from "@services/common/utils/settings"
//HOOK
import {useAuthorized} from "@customHooks/common/account/useAuthorized";
import {useAllInfo} from "@customHooks/common/account/useAllInfo"
//SVG
import TwilioChat from "@src/modules/components/basic/common/others/TwilioChat";
import useLocalizedRoutes from '@customHooks/common/useLocalizedRoutes'

const LoadingPage = ({element}) => {
  //Variables necesarias porque lka página está fuera del "scope"
  const { setAuthorized, setAgent, axiosInstance, logoutLoading } = useAuthorized() //authorized, 
  const [hasMounted, setHasMounted] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const  {setUserInfo, setAccountInfo, getAllInfo} = useAllInfo() //userInfo
  const { initialNavigateToCurrency, getRouteId} = useLocalizedRoutes()

  const onLoad = ()=>{
    const token = getFromStorage("token")
    const timeStamp = getFromStorage("stamp")
    const agent = getFromStorage("agent")
    if(token && timeStamp){
      //Existen los dos
      if(Number(timeStamp)<new Date().getTime()){
        axiosInstance({
          method: "post",
          headers: {"Content-Type": "application/x-www-form-urlencoded", "Authorization": `Basic ${AUTH_PASS}`},
          url: `${URL_REFRESH_TOKEN}`,//url: `${URL_BASE}${URL_REFRESH_TOKEN}`,
          params: {refresh_token: getRefreshToken(), grant_type: "refresh_token", client_id: CLIENT_ID}
        }).then(tokenRefreshResponse => {
            addToStorage("token", tokenRefreshResponse.data)
            addToStorage("stamp", new Date().getTime()+(Number(tokenRefreshResponse.data.expires_in)*1000))
            setAuthorized(true) //Se hizo el refresh Token
            setAgent(agent)
            getAllInfo(()=>{
              setLoadComplete(true)
            }, true)
        }).catch(error => {
          removeFromStorage("token")
          removeFromStorage("stamp")
          removeFromStorage("agent")
          setUserInfo(null)
          setAccountInfo(null)
          setAuthorized(false) //Falló el refresh token
          setLoadComplete(true)
        })
      }else{
        setAuthorized(true)
        setAgent(agent)
        getAllInfo((currency)=>{
          setLoadComplete(true)
          const routeId = getRouteId(window.location.pathname)
          if(currency && routeId){
            initialNavigateToCurrency({route: routeId, currency})
          }
        }, true)
      }
    }else{
    //NO existen
      removeFromStorage("token")
      removeFromStorage("stamp")
      removeFromStorage("agent")
      setAuthorized(false) //NO hay tokens
      setUserInfo(null)
      setAccountInfo(null)
      setLoadComplete(true)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onLoad,[])

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return <Suspense fallback={<PageLoader/>}>
    {hasMounted?
      loadComplete && !logoutLoading?
        <>
          {element}
          <TwilioChat/>
        </>
        :
        <PageLoader/>   
      :null}
  </Suspense>
}
export default LoadingPage;