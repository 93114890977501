import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.SENTRY_DSN, // this is the default
  environment: process.env.SENTRY_ENVIRONMENT,
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});