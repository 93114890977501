import {URL_CASH_TICKET_OPTIONS} from '@services/common/utils/settings'
import processCall from '@services/common/utils/servicePetition'
import responseEvaluation from '@services/common/utils/serviceResponseEvaluation'

const formatApiResponse = apiResponse => {
  const data =  apiResponse.data.data.elements
  return data
}

const getCashTicketOptions = async ({axiosInstance, country}) => {
  //const params = "?totalAmount="+totalAmount+"&currency="+currency
  const apiURL = `${URL_CASH_TICKET_OPTIONS}${country}`
  const response = await processCall({
    axiosInstance: axiosInstance,
    method: "GET",
    url: apiURL,
    useToken: "TOKEN_YES",
    callData:{body:null, bodyEncode: "JSON"}
  })
  //Evaluamos la respuesta del processCall
  const evaluation = responseEvaluation(response)
  if(evaluation.status === "ok"){
    evaluation.formattedData = formatApiResponse(response.data)
  }
  return evaluation
}
export default getCashTicketOptions