import React, { useState, useEffect } from 'react'
import { getRefreshToken, addToStorage, removeFromStorage} from '@utils/storage' //,getFromStorage
import { AUTH_PASS, URL_BASE, URL_REFRESH_TOKEN, CLIENT_ID } from "@services/common/utils/settings"
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const GlobalContext = React.createContext({})

export function GlobalProvider ({children}) {
  const [authorized, setAuthorized] = useState(null)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const [loginStatus, setLoginStatus] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const [accountInfo, setAccountInfo] = useState(null)
  const [alertData, setAlertData] = useState(null)
  const [ipData, setIpData] = useState(null)
  const [redirectRoute, setRedirectRoute] = useState(null)
  const [sendOptions, setSendOptions] = useState(null)
  const [waitTime, setWaitTime] = useState(0)
  const [cart, setCart] = useState(null)
  const [cartCount, setCartCount] = useState(null)
  const [credit, setCredit] = useState(null)
  const [product, setProduct] = useState(null)
  const [search, setSearch] = useState(null)
  const [tempValue, setTempValue] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [langSwitcher, setLangSwitcher] = useState(true)
  const axiosInstance = axios.create({baseURL:URL_BASE})
  const [agent, setAgent] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [availableServices, setAvailableServices] = useState(null)
  const [hasData, setHasData] = useState(false)
  const [permissionGranted, setPermissionGranted] = useState(null)
  /*Payment*/
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [availableMethods, setAvailableMethods] = useState(null)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [balanceData, setBalanceData] = useState(null)
  const [cashTicketOptions, setCashTicketOptions] = useState(null)

  useEffect(() => {
    const timer = waitTime > 0 && setInterval(() => setWaitTime(waitTime - 1), 1000)
    return () => clearInterval(timer)
  }, [waitTime])
  
  const refreshAuthLogic = (failedRequest) => {
    const refreshToken = getRefreshToken()
    if(refreshToken){
      axiosInstance({
        method: "post",
        headers: {"Content-Type": "application/x-www-form-urlencoded", "Authorization": `Basic ${AUTH_PASS}`},
        url: `${URL_REFRESH_TOKEN}`,//url: `${URL_BASE}${URL_REFRESH_TOKEN}`,
        params: {refresh_token: getRefreshToken(), grant_type: "refresh_token", client_id: CLIENT_ID}
      }).then(tokenRefreshResponse => {
        //if(tokenRefreshResponse.status === 200){
          addToStorage("token", tokenRefreshResponse.data)
          addToStorage("stamp", new Date().getTime()+(Number(tokenRefreshResponse.data.expires_in)*1000))
          failedRequest.response.config.headers['Authorization'] = 'bearer ' + tokenRefreshResponse.data.access_token;
          return Promise.resolve();
        /*}else{
          removeFromStorage("token")
          removeFromStorage("stamp")
          removeFromStorage("agent")
          setAuthorized(false)
          return Promise.reject();
        }*/
      }).catch(error => {
        //consolelog(error)
        removeFromStorage("token")
        removeFromStorage("stamp")
        removeFromStorage("agent")
        setAuthorized(false)
        setUserInfo(null)
        setAccountInfo(null)
        return Promise.resolve()
        //return Promise.reject("Back in AX");
      })
    }else{
      console.log("NO RT, Back")
    }
  }
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {pauseInstanceWhileRefreshing: true});

  const goLogout = ({callback})=>{
    setUserInfo(null)
    setAccountInfo(null)
    setPaymentMethods(null)
    setAvailableMethods(null)
    setSelectedMethod(null)
    setBalanceData(null)
    setAgent(null)
    setLoginStatus(null)
    setTempValue(null)
    setAuthorized(false)
    setHasData(false)
    setCashTicketOptions(null)
    //ENvíos logout
    //document.cookie = "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if(typeof callback === "function"){
      callback()
    }
  }

  return <GlobalContext.Provider value={{
    authorized,
    setAuthorized,
    logoutLoading,
    setLogoutLoading,
    loginStatus,
    setLoginStatus,
    userInfo,
    setUserInfo,
    accountInfo,
    setAccountInfo,
    alertData,
    setAlertData,
    ipData,
    setIpData,
    redirectRoute,
    setRedirectRoute,
    waitTime,
    setWaitTime,
    sendOptions,
    setSendOptions,
    cart,
    setCart,
    cartCount,
    setCartCount,
    credit,
    setCredit,
    axiosInstance,
    product,
    setProduct,
    tempValue,
    setTempValue,
    firstLoad,
    setFirstLoad,
    langSwitcher,
    setLangSwitcher,
    agent,
    setAgent,
    search,
    setSearch,
    currency,
    setCurrency,
    paymentMethods,
    setPaymentMethods,
    availableMethods,
    setAvailableMethods,
    balanceData,
    setBalanceData,
    cashTicketOptions,
    setCashTicketOptions,
    selectedMethod,
    setSelectedMethod,
    hasData,
    setHasData,
    goLogout,
    availableServices,
    setAvailableServices,
    permissionGranted,
    setPermissionGranted
  }}>
    {children}
  </GlobalContext.Provider>
}
export default GlobalContext