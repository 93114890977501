import {useContext} from 'react'
//Context
import GlobalContext from "@context/global/GlobalContext";
//Hooks
import useCancellablePromise from '@customHooks/common/useCancellablePromise'
//Intl
import useLocalizedRoutes from '@customHooks/common/useLocalizedRoutes'
//Getters
import getLogoutService from '@services/common/login/getLogout'

export function useLogout() {
  const {navigate} = useLocalizedRoutes()
  const {goLogout,
    logoutLoading,
    setLogoutLoading,
    axiosInstance
} = useContext(GlobalContext)
  const { cancellablePromise } = useCancellablePromise()

  const callLogout = (callback=null)=>{
    const logoutCallback = ()=>{
      setLogoutLoading(false)
      if(typeof callback === "function"){
        callback()
      }else{
        navigate("HOME")
      }
    }
    if(!logoutLoading){
      setLogoutLoading(true)
      cancellablePromise(getLogoutService(axiosInstance))
      .then(response => {
        goLogout({callback: logoutCallback})
      })
      .catch(error => {
        goLogout({callback: logoutCallback})
      })
    }
  }
  return {loading: logoutLoading, callLogout}
}