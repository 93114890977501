import { createTheme } from '@mui/material/styles'

const defaultTheme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1E4ACC',
      dark: '#094296',
      light: '#317dee',
    },
    secondary: {
      main: '#253565',
      dark: '#00255E',
      light: '#304582',
    },
    inverted: {//color blanco
      main: '#fff',
      dark: '#fff',
      light:'#fff',
    },
    terciary:{//gris
      main: '#DEDEDE',
      dark: '#6E7A86',
      light: '#f2f2f2',
    },
    footer: {
      main: '#8cb2cd'
    },
    text: {
      primary: '#000000',
    },
    menu: {
      default: '#747474',
      active: '#1E4ACC',
    },
    error: {
      main: '#ff424d',
      dark: '#ff424d',
      light:'#ff424d',
    },
    success: {
      main: '#51b270',
      dark: '#51b270',
      light:'#51b270',
    },
    background: {
      main: '#FFFFFF',
      dark: '#000000',
      light: '#f0f3f8',
    },
    contact:{
      colorA: "#a3dbca",
      colorB: "#f2a6a6",
      colorC: "#64b2cd",
      colorD: "#b18ea6"
    }
  }
});
const fontSizes = {
  h1:2,
  h2:1.835,
  h3:1.67,
  h4:1.505,
  h5:1.34,
  h6:1.175,
  body1:1,
  body2:0.875,
  wpBody:1,
  slideTitle:5
}
const mobileRatio= 0.75
const lineHeightRatio= 1.2
// A custom theme for this app
const theme = createTheme({
  breakpoints: defaultTheme.breakpoints,
  palette: defaultTheme.palette,
  typography: {
    fontFamily: 'Nunito Sans',
    h1: {
        fontSize: `${fontSizes.h1*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h1}rem`,
        },
    },
    h2: {
        fontSize: `${fontSizes.h2*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h2}rem`,
        },
    },
    h3: {
        fontSize: `${fontSizes.h3*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h3}rem`,
        },
    },
    h4: {
        fontSize: `${fontSizes.h4*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h4}rem`,
        },
    },
    h5: {
        fontSize: `${fontSizes.h5*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h5}rem`,
        },
    },
    h6: {
        fontSize: `${fontSizes.h6*mobileRatio}rem`,
        lineHeight: lineHeightRatio,
        [defaultTheme.breakpoints.up('md')]: {
            fontSize: `${fontSizes.h6}rem`,
        },
    },
    body1: {
      fontSize: `${fontSizes.body1}rem`,
      lineHeight:lineHeightRatio
    },
    body2: {
      fontSize: `${fontSizes.body2}rem`,
      lineHeight: lineHeightRatio
    },
    wpBody: {
      fontSize: `${fontSizes.wpBody}rem`,
      lineHeight:lineHeightRatio,
      "& a":{textDecoration:"none", color:defaultTheme.palette.primary.main, "&:hover":{color:defaultTheme.palette.secondary.main}, "&:visited":{color:"inherit"}, "&:active":{color:"inherit"}},
      "& ol li":{marginBottom: "1.15rem"},
      "& ul li":{marginBottom: "1.15rem"},
      "& ol li::marker":{fontSize: "1.15rem", color: defaultTheme.palette.primary.main},
      "& ul li::marker":{fontSize: "1.15rem", color: defaultTheme.palette.primary.main}
    },
    /*Slides*/
    slideTitle:  {
      fontSize: `${fontSizes.slideTitle*mobileRatio}rem`,
      lineHeight:lineHeightRatio,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: `${fontSizes.slideTitle}rem`,
    },
    },

    button: {
      root: {                
        fontSize: '1rem',
      },
      sizeLarge: {
        fontSize: '3rem',
      },
      sizeSmall: {
        fontSize: '0.2rem',
      }
    },
    fontSize: 16,
    fontWeightMedium: 400,
  },
  shape: {
    borderRadius: 8 //1= 8px, 2 = 16px
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '0.9rem',
          padding:'7px 23px',
          lineHeight: '1.4',
          textTransform:'none'
        },
        sizeLarge: {
          fontSize: '1rem',
          padding:'10px 33px',
          lineHeight: '1.4',
          fontWeight:'700'
        },
        sizeSmall: {
          fontSize: '0.8125rem',
          padding:'3px 13px',
          lineHeight: '1.4'
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '8px',
          [defaultTheme.breakpoints.up('md')]: {
            borderRadius: '16px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        // Name of the slot
        root: {
          backgroundColor:'transparent'
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '3.2rem',
        },
        fontSizeSmall: {
          fontSize: '1rem',
        }
      } 
    },

    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: '8px 8px 0 0',
          backgroundColor: 'rgba(255,255,255,0.5)',
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: defaultTheme.palette.primary.dark,
          fontSize: "1rem",
          [defaultTheme.breakpoints.down("sm")]: {
            fontSize: ".8rem"
          },
          "&:hover": {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.inverted.main,
            borderColor: defaultTheme.palette.primary.dark,
          },
          "&.Mui-selected": {
            backgroundColor: defaultTheme.palette.inverted.main,
            color: defaultTheme.palette.primary.main,
            "&:hover": {
              //backgroundColor: 'rgba(255,255,255,0.9)', 
              color: defaultTheme.palette.primary.main  
            }
          }
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: defaultTheme.palette.mode === 'dark' ? defaultTheme.palette.primary.dark: defaultTheme.palette.primary.light,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                defaultTheme.palette.mode === 'light'
                  ? defaultTheme.palette.grey[100]
                  : defaultTheme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: defaultTheme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: defaultTheme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: defaultTheme.transitions.create(['background-color'], {
              duration: 500,
            }),
          }
        }
      }
    }
  }, 
})

export default theme;
