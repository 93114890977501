import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
//Components
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
//ICONS
import Logo from '@static/images/svg/cbll-isotipo-color.svg'

export default function ErrorFallback({error}) {
    return (
        <Stack maxWidth="450px" alignItems="center" spacing={2} sx={{my:4, mx:"auto", p:3}}>
          <Box component="img" src={Logo} sx={{width:{xs:"70px", md:"90px"}, height:"auto"}}/>
          <Typography variant="h5" align="center" sx={{mt:3, fontWeight:"700"}}>
              No se puede mostrar Cuballama.com
          </Typography>
          <Typography variant="body1" align="center" sx={{mt:1}}>
            No se ha podido procesar tu petición debido al siguiente error
          </Typography>
          <Divider sx={{borderColor:'rgba(0,0,0,0.2)', borderBottomWidth:"1px", width:"100px", pt:2}}/>
          <Typography variant="body2" align="center" sx={{color:"#6CAFEA"}}>
            {error.message}
          </Typography>
        </Stack>
    );
}
