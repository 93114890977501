import { AUTH_PASS, AUTH_PASSB } from "@services/common/utils/settings"
import {getAccessToken} from '@utils/storage'

const processCall= async ({axiosInstance, method, url, useToken = "TOKEN_NO", baseURL = null, callData:{body = null, bodyEncode = "JSON"}={}}) => {
  const accessToken =  getAccessToken()
  try{
    let response
    if(useToken === "TOKEN_YES" && !accessToken){//SE NECESITA TOKEN Y NO HAY
      throw new Error({error:"token not available"})
    }else{
      if(useToken === "TOKEN_NO" || useToken === "TOKEN_AUTH" || useToken === "TOKEN_AUTHB" || (useToken === "TOKEN_BOTH" && !accessToken)){//NO SE NECESITA TOKEN
        const tempType = useToken === "TOKEN_AUTH"?"AUTH_PASS":useToken === "TOKEN_AUTHB"?"AUTH_PASSB":null
        response = await fetchCall({axiosInstance, method, url, baseURL, data: {token: null, tokenType: tempType, body: body, bodyEncode: bodyEncode}})
      }else{//SE NECESITA TOKEN Y HAY
        response = await fetchCall({axiosInstance, method, url, baseURL, data: {token: accessToken, tokenType: "AUTH", body: body, bodyEncode: bodyEncode}})
      }
    }
    if(response.data.error === "invalid_grant"){
      //Falló el refresh
      return {ok: false, status: response.status, data:{data:response.data}}  
    }else{
      return {ok: response.ok, status: response.status, data: {status:response.status, data:response.data}}
    }  
  }catch(error){
    return {ok: false, status: 500, data: error}
  } 
}
  
export const fetchCall = async ({axiosInstance, method, url, baseURL, data}) => {
  const {token = null, tokenType = null, body = null, bodyEncode} = data
  const options = {
    method: method,
    headers: getHeader(token, tokenType, bodyEncode),
    url: url,
    ...baseURL && {baseURL: baseURL}
  }
  if(bodyEncode==="JSON" || bodyEncode==="ENCODED"){
    options.data = body; 
  }else if("FORMDATA"){
    const formData = new FormData()
    for (const key in body) {
      if (key === 'field') {
        formData.append(key, body[key][1])
      } else {
        formData.append(key, body[key])
      }
    }
    options.data = formData;
  }else{
    options.params = body;
  }
  const response = axiosInstance(options)
  //const response = instance(options)
  .then(response=>{
    return response
  }).catch(error=>{
    return error.response
  })
  return response
}

const getHeader = (token, tokenType, bodyEncode="") => {
  let header = {"Content-Type": getBodyEncode(bodyEncode)}
  switch(tokenType){
    case "AUTH_PASS":
      header.Authorization= `Basic ${AUTH_PASS}`
    break
    case "AUTH_PASSB":
      header.Authorization= `Basic ${AUTH_PASSB}`
    break
    case"AUTH":
      header.Authorization= `bearer ${token}`
    break
    default:
      //header={"Content-Type": bodyEncode === "JSON"?"application/json":"application/x-www-form-urlencoded",}
    break
  }
  return header
}
const getBodyEncode = (bodyEncode="") => {
  let result =""
  switch(bodyEncode){
    case "JSON":
      result = "application/json"
    break
    case "FORMDATA":
      result = "multipart/form-data"
    break
    default:
      result = "application/x-www-form-urlencoded"
    break
  }
  return result
}
export default processCall